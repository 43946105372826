import React, { useEffect, useState } from 'react';
import {
    AutoComplete,
    Col,
    Row,
    Slider,
    Table,
    Button,
    Tooltip,
    Typography,
    Spin,
    Form,
    Input,
    message,
    Cascader
} from 'antd';
import { EyeOutlined, PlusOutlined, MinusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useList, useNavigation, useApiUrl } from '@refinedev/core';
import { useLocation, useParams } from 'react-router-dom';

import { useAssignmentData } from 'contexts/AssignmentData';
import { createExplanationPDF, createMemoPDF, createPDF, findMinMaxPoints, findUniqueUrlAttribute, sortByNumberString } from 'utils/functions';
import { ImageList, PdfModal } from 'components';
import { IImage, ISection, ITopic } from 'interfaces';
import { axiosInstance } from '@refinedev/simple-rest';

import './generate.css';
import { FinishModal, MainModal } from './modals';
import { useTitle } from 'contexts/TitleContext';

const { Text } = Typography;

interface IFilterKeys {
    [key: string]: string;
}

const filterKeyNames: IFilterKeys = {
    section: 'section_id',
    topic: 'topic_id',
    points_possible: 'points_possible',
    paper: 'paper',
    seriesYear: 'seriesYear',
}

export const GeneratePDF: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data: imageData, isLoading } = useList({
        resource: `images?type=question&course_id=${id}`
    });

    const { data: sectionData } = useList({
        resource: `sections?type=question&course_id=${id}`
    });

    const { data: topicData } = useList({
        resource: `topics?type=question&course_id=${id}`
    });

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Generate Assignment');
    }, []);

    const sections = (sectionData?.data as ISection[] ?? []).sort((a, b) => sortByNumberString(a.name, b.name));
    const topics = (topicData?.data as ITopic[] ?? []).sort((a, b) => sortByNumberString(a.name, b.name));

    const [images, setImages] = useState<IImage[]>([]);
    const [selectedImages, setSelectedImages] = useState<IImage[]>([]);
    const [filteredImages, setFilteredImages] = useState<IImage[]>([]);

    const [totalPoints, setTotalPoints] = useState(0);
    const [subheading, setSubheading] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFlagFormOpen, setIsFlagFormOpen] = useState(false);
    const [flagReason, setFlagReason] = useState('');
    const [previewImage, setPreviewImage] = useState<IImage | null>(null);

    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [generatedPDF, setGeneratedPDF] = useState<string | null>(null);
    const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
    const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
    const [isFinalizing, setIsFinalizing] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');

    const { push } = useNavigation();
    const apiUrl = useApiUrl();
    const { assignmentData, setAssignmentData } = useAssignmentData();

    const [form] = Form.useForm();

    useEffect(() => {
        if (imageData?.data) {
            const image_data = imageData.data as IImage[];
            setImages(image_data);
        }
    }, [imageData]);

    const defaultPointsRange = findMinMaxPoints(images);

    const [filters, setFilters] = useState<{
        section?: number;
        topic?: number;
        points_possible?: [number, number];
        paper?: string[][];
        seriesYear?: string[][];
    }>({});

    const [options, setOptions] = useState<{
        section: ISection[];
        topic: ITopic[];
        points_possible: [number, number];
        paper: string[];
        seriesYear: string[];
    }>({
        section: [],
        topic: [],
        points_possible: defaultPointsRange,
        paper: [],
        seriesYear: [],
    });

    const [lastUpdatedFilter, setLastUpdatedFilter] = useState<string>("");

    const urlFilter = (item: IImage, attribute_position: number, collection: string[][] | undefined) => {
        const url_parts = item.image_urls[0].url.split("/");
        const name_parts = url_parts[url_parts.length - 1].split("_");
        return collection?.some((paper) => paper.includes(name_parts[attribute_position]));
    }

    useEffect(() => {
        let filteredData = images;

        if (filters.section) {
            filteredData = filteredData.filter((item) => item.section_id === filters.section);
        }
        if (filters.topic) {
            if (lastUpdatedFilter === "section") {
                filters.topic = undefined;
            }
            else {
                filteredData = filteredData.filter((item) => item.topic_id === filters.topic);
                filters.section = topics.find((item) => item.id === filters.topic)?.section_id;
            }
        }

        if (filters.paper?.length) {
            filteredData = filteredData.filter((item) => urlFilter(item, 3, filters.paper));
        }

        if (filters.seriesYear) {
            filteredData = filteredData.filter((item) => urlFilter(item, 1, filters.seriesYear));
        }

        const newOptions = {
            section: Array.from(new Set(sections.filter((item) => filteredData.find(image => image.section_id === item.id) ? item : null))),
            topic: Array.from(new Set(topics.filter((item) => filteredData.find(image => image.topic_id === item.id) ? item : null))),
            points_possible: findMinMaxPoints(filteredData),
            paper: findUniqueUrlAttribute(images, 3),
            seriesYear: findUniqueUrlAttribute(images, 1),
        };

        setOptions(newOptions);
        updateImageList();
    }, [images, filters]);

    useEffect(() => {
        setSubheading(`TOTAL:        /${totalPoints}`);
    }, [totalPoints]);

    const handleFilterChange = (attribute: string, value: string | number | undefined | [number, number] | string[][]) => {
        setLastUpdatedFilter(attribute);
        setFilters((prev) => ({
            ...prev,
            [attribute]: value,
        }));
    };

    const updateImageList = () => {
        const filteredList = images.filter((item) =>
            Object.entries(filters).every(([key, value]) => {
                if (value === undefined) {
                    return true;
                }

                const filteredKey = filterKeyNames[key] as keyof IImage
                if (key === "points_possible")
                    return (item[filteredKey] as number) >= (value as [number, number])[0] && (item[filteredKey] as number) <= (value as [number, number])[1];
                else if (key === "paper" || key === "seriesYear")
                    return urlFilter(item, key === "paper" ? 3 : 1, value as string[][]);

                return item[filteredKey] === value;
            }),
        );
        setFilteredImages(filteredList);
    }

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (_: any, record: IImage) => {
                return record.used
                    ? <Text type="secondary">{record.description}</Text>
                    : <Text>{
                        record.flagged ? 
                            <Tooltip title="Flagged">
                                <span><ExclamationCircleOutlined style={{ color: 'orange' }}/> </span>
                            </Tooltip>
                        : !record.answer_image_urls.length ? 
                            <Tooltip title="No Memo">
                                <span><ExclamationCircleOutlined style={{ color: 'red' }}/> </span>
                            </Tooltip>
                        : ''
                    }
                        {record.description}
                    </Text>
            },
        },
        {
            title: 'Points',
            dataIndex: 'points_possible',
            key: 'points_possible',
            render: (_: any, record: IImage) => {
                return record.used
                    ? <Text type="secondary">{record.points_possible}</Text>
                    : <Text>{record.points_possible}</Text>
            },
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            render: (_: any, record: IImage) => {
                var year = "";
                if (record.image_urls.length) {
                    const url_parts = record.image_urls[0].url.split("/")
                    const name_parts = url_parts[url_parts.length - 1].split("_")
                    year = name_parts[1].toUpperCase();
                }
                return record.used
                    ? <Text type="secondary">{year}</Text>
                    : <Text>{year}</Text>
            },
        },
        {
            title: 'Paper',
            dataIndex: 'paper',
            key: 'paper',
            render: (_: any, record: IImage) => {
                var paper = "";
                if (record.image_urls.length) {
                    const url_parts = record.image_urls[0].url.split("/")
                    const name_parts = url_parts[url_parts.length - 1].split("_")
                    paper = name_parts[3][0] + name_parts[3][1];
                }
                return record.used
                    ? <Text type="secondary">{paper}</Text>
                    : <Text>{paper}</Text>
            },
        },
        {
            title: 'Preview',
            dataIndex: 'preview',
            key: 'preview',
            render: (_: any, record: IImage) => (
                <Tooltip title="Preview Image">
                    <EyeOutlined onClick={() => handlePreview(record)}/>
                </Tooltip>
            ),
        },
        {
            title: '+/-',
            dataIndex: 'addRemove',
            key: 'addRemove',
            render: (_: any, record: IImage) => {
                const isSelected = selectedImages.some((item) => {
                    return item.id === record.id
                });
                const IconComponent = isSelected ? MinusOutlined : PlusOutlined;
                const onClick = () => handleImageSelect(record, isSelected);

                return (
                    <IconComponent onClick={onClick}/>
                );
            },
        },
    ];

    const showModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        calculateTotalPoints();
    }, [selectedImages]);

    const calculateTotalPoints = () => {
        let total = selectedImages.reduce((sum, item) => sum + item.points_possible, 0);
        setTotalPoints(total);
    };

    const setSelected = (images: IImage[]) => {
        setSelectedImages(images);
    }

    const handleAddOrRemove = () => {
        if (previewImage) {
            handleImageSelect(previewImage, selectedImages.includes(previewImage));
        }
        setIsModalOpen(false);
    };

    const handleImageSelect = (image: IImage, isSelected: boolean) => {
        const newSelectedImages = isSelected
            ? selectedImages.filter((item) => item.id !== image.id)
            : [...selectedImages, image];
        setSelected(newSelectedImages);
    };

    const handlePreview = (image: IImage) => {
        setPreviewImage(image);
        showModal();
    };

    const handlePDFPreview = async () => {
        setIsPDFModalOpen(true);
    }

    const handleClear = () => {
        setSelected([]);
    };

    const handleFlag = async (image: IImage, action: string) => {
        if (isFlagFormOpen || action === 'remove') {
            const response = await axiosInstance.post(`${apiUrl}/images/${image.id}/flag`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    flagged: !image.flagged,
                    reason: flagReason
                }
            });

            if (response.status === 200) {
                const newImages = images.map(img => img.id === image.id ? { ...img, flagged: !image.flagged, flag_reason: flagReason } : img);
                setImages(newImages);
                setIsModalOpen(false);
                setIsFlagFormOpen(false);
                setFlagReason('');
            }
        } else {
            setIsFlagFormOpen(true);
        }
    };

    const handleFinishSubmit = async () => {
        setIsFinalizing(true);
        try {
            const values = await form.validateFields();
            if (!values) throw "No values";
            handleSubmit(values.title);
        } catch (error) {
            message.error('Please enter a file name before continuing');
        }
        finally {
            setIsFinalizing(false);
        }
    }

    const handleSubmit = async (name: string) => {
        const hasExplanations = selectedImages.some(image => image.explanation?.image_url);
        const { createdPDF } = await createPDF(selectedImages, apiUrl, 'portrait', name, subheading);
        const { createdPDF: createdMemo } = await createMemoPDF(selectedImages, apiUrl, name);

        let createdExplanationPDF = null;
        if (hasExplanations) {
            createdExplanationPDF = await createExplanationPDF(selectedImages, apiUrl, 'portrait', name, subheading);
        }

        if (!createdMemo) return;

        const formData = new FormData();
        formData.append('questions', createdPDF, `${name}.pdf`);
        formData.append('memo', createdMemo, `${name} Memo.pdf`);

        if (createdExplanationPDF) {
            formData.append('explanation', createdExplanationPDF.createdPDF, `${name} Explanation.pdf`);
        }

        const response = await axiosInstance.post(`${apiUrl}/upload?type=multiple&syllabus_id=${images[0].syllabus_id}&name=${name}&points_possible=${totalPoints}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        const file_id = response.data.fileId;
        const question_ids = selectedImages.map(image => image.id);

        await axiosInstance.post(`${apiUrl}/files/${file_id}/questions`, question_ids, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        await axiosInstance.get(`${apiUrl}/used?question_ids=[${question_ids}]`);

        const pdfFile = {
            uid: `generated-${assignmentData && assignmentData.attachments ? assignmentData.attachments.length : 1}`,
            name: `${name}.pdf`,
            status: 'done',
            url: response.data.fileUrl
        };

        if (assignmentData) {
            const newAttachments = assignmentData.attachments ? [...assignmentData.attachments, pdfFile] : [pdfFile];
            setAssignmentData({
                ...assignmentData,
                pointsPossible: totalPoints,
                attachments: newAttachments
            });
        }
        if (redirect === 'quest') {
            push(`/courses/${id}/assignments/create?type=quest`);
        }
    }

    const handleFinish = () => {
        setIsFinishModalOpen(true);
    };

    const handleMainModalClose = (value: boolean) => {
        if(!value){
            setPreviewImage(null);
        }
        setIsModalOpen(value);
    }

    return (
        <Row gutter={16}>
            <Col span={18}>
                <Row gutter={[12, 12]} style={{ marginBottom: '8px' }}>
                    <Col>
                        <Text>Level </Text>
                        <AutoComplete
                            style={{ width: 250 }}
                            options={options.section.map(section => ({ value: section.id, label: section.name }))}
                            placeholder="Select a level"
                            onChange={(value) => handleFilterChange('section', value != "" ? value : undefined)}
                            allowClear
                            value={sections.find(section => section.id === filters.section)?.name}
                            filterOption={(inputValue, option: any) =>
                                option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            } />
                    </Col>
                    <Col>
                        <Text>Expedition </Text>
                        <AutoComplete
                            style={{ width: 250 }}
                            options={options.topic.map(topic => ({ value: topic.id, label: topic.name }))}
                            placeholder="Select an expedition"
                            onChange={(value) => handleFilterChange('topic', value != "" ? value : undefined)}
                            allowClear
                            value={topics.find(topic => topic.id === filters.topic)?.name}
                            filterOption={(inputValue, option: any) =>
                                option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            } />
                    </Col>
                    <Col>
                        <Text>Paper </Text>
                        <Cascader
                            style={{ width: 150 }}
                            options={options.paper.map(paper => ({ value: paper, label: paper }))}
                            placeholder="Select papers"
                            onChange={(value) => handleFilterChange('paper', value.length ? value as string[][] : undefined)}
                            multiple
                        />
                    </Col>
                    <Col>
                        <Text>Series/Year </Text>
                        <Cascader
                            style={{ width: 150 }}
                            options={options.seriesYear.map(seriesYear => ({ value: seriesYear, label: seriesYear }))}
                            placeholder="Select series/year"
                            onChange={(value) => handleFilterChange('seriesYear', value.length ? value as string[][] : undefined)}
                            multiple
                        />
                    </Col>
                    <Col>
                        <Text>Maximum Points</Text>
                        <Slider
                            min={options.points_possible[0] ?? defaultPointsRange[0]}
                            max={options.points_possible[1] ?? defaultPointsRange[1]}
                            range
                            defaultValue={defaultPointsRange}
                            value={filters.points_possible ?? defaultPointsRange}
                            onChange={(value:any) => handleFilterChange('points_possible', value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {isLoading ? <Spin /> :
                            <Table dataSource={filteredImages ?? images} columns={columns} />}
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                <div style={{
                    padding: '16px',
                    margin: '55px auto',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#ffffff'
                }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3>Current Selection</h3>
                        {totalPoints > 0 && (
                            <div style={{ fontWeight: 'bold' }}>
                                Total:{" "}
                                <Input
                                    style={{ width: '50px', fontWeight: 'bold' }}
                                    value={totalPoints}
                                    onChange={(e) => setTotalPoints(parseInt(e.target.value))}
                                />
                            </div>
                        )}
                    </div>
                    <ImageList
                        items={selectedImages}
                        setItems={setSelected}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button
                            danger
                            onClick={handleClear}>Clear
                        </Button>
                        <Button
                            onClick={handlePDFPreview}>Preview
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleFinish}>Finish
                        </Button>
                    </div>

                    <PdfModal
                        images={selectedImages}
                        apiUrl={apiUrl}
                        isModalOpen={isPDFModalOpen}
                        subheading={subheading}
                        onClose={() => setIsPDFModalOpen(false)}
                    />

                    <MainModal
                        isModalOpen={isModalOpen}
                        generatedPDF={generatedPDF}
                        isGeneratingPDF={isGeneratingPDF}
                        previewImage={previewImage}
                        selectedImages={selectedImages}
                        isFlagFormOpen={isFlagFormOpen}
                        flagReason={flagReason}
                        setIsModalOpen={handleMainModalClose}
                        handleAddOrRemove={handleAddOrRemove}
                        handleFlag={handleFlag}
                        setIsFlagFormOpen={setIsFlagFormOpen}
                        setFlagReason={setFlagReason}
                    />

                    <FinishModal
                        isFinishModalOpen={isFinishModalOpen}
                        isFinalizing={isFinalizing}
                        form={form}
                        setIsFinishModalOpen={setIsFinishModalOpen}
                        handleFinishSubmit={handleFinishSubmit}
                    />
                </div>
            </Col>
        </Row >

    );
};