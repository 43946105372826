import React, { useEffect, useState } from 'react';
import {
    Typography,
    Form,
    Input,
    InputNumber,
    Button,
    Upload,
    Row,
    Col,
    List,
    Modal,
    message,
    Spin,
    Tag
} from 'antd';
import { useApiUrl, useList, useNavigation } from '@refinedev/core';
import { EyeOutlined, PlusOutlined, SortAscendingOutlined, SortDescendingOutlined, UploadOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { IFile } from 'interfaces';
import { useAssignmentData } from 'contexts/AssignmentData';
import { useParams } from 'react-router-dom';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './create.css';

import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { axiosInstance } from '@refinedev/simple-rest';
import { DefaultPdfModal } from 'components/ImageSelection';

const { Dragger } = Upload;

export const CreateScroll: React.FC = () => {
    const { push } = useNavigation();
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    const apiUrl = useApiUrl();

    const { assignmentData, setAssignmentData } = useAssignmentData();
    const [selectedPDF, setSelectedPDF] = useState<IFile | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPdfs, setFilteredPdfs] = useState<IFile[]>([]);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const { data: questionData } = useList({
        resource: `files?type=questions`
    });

    const { data, isLoading } = useList({
        resource: `files?type=memo`
    });

    const { data: explanationData } = useList({
        resource: `files?type=explanation`
    });

    const pdfs = [
        ...(questionData?.data as IFile[] || []),
        ...(explanationData?.data as IFile[] || []),
        ...(data?.data as IFile[] || [])
    ];

    const filterAndSortPdfs = () => {
        const filtered = pdfs.filter((pdf) =>
            pdf.title.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const sorted = filtered.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);

            return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        });

        setFilteredPdfs(sorted);
    };

    useEffect(() => {
        if (pdfs) {
            filterAndSortPdfs();
        }
    }, [pdfs, searchQuery, sortDirection]);

    const onEditorStateChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
    };

    const handleModalOpen = (file: IFile) => {
        setSelectedPDF(file);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedPDF(null);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (assignmentData) {
            form.setFieldsValue({
                assignmentName: assignmentData.assignmentName,
                attachments: assignmentData.attachments,
                pointsPossible: assignmentData.pointsPossible,
                attemptsAllowed: assignmentData.attemptsAllowed
            });
        }
    }, [assignmentData, form]);

    const getCurrentFormData = () => {
        const currentAssignmentData = {
            assignmentName: form.getFieldValue('assignmentName'),
            instructions: stateToHTML(editorState.getCurrentContent()),
            attachments: form.getFieldValue('attachments'),
            pointsPossible: form.getFieldValue('pointsPossible'),
            attemptsAllowed: form.getFieldValue('attemptsAllowed')
        };

        return currentAssignmentData;
    }

    const getFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleAddFromFile = (file: IFile) => {
        const file_to_add: UploadFile = {
            uid: `${Date.now()}-${file.title}`,
            name: file.title,
            status: 'done',
            url: file.url
        };
        const data = getCurrentFormData();
        const attachments = data.attachments ? [...data.attachments, file_to_add] : [file_to_add];
        const points_possible = file.points_possible;

        form.setFieldValue('pointsPossible', points_possible);
        setAssignmentData({ ...data, attachments, points_possible });
    };

    const onFinish = async () => {
        const questData = getCurrentFormData();
        setAssignmentData(questData);

        const assignment = {
            name: questData.assignmentName,
            points_possible: questData.pointsPossible,
            attempts_allowed: questData.attemptsAllowed,
            instructions: questData.instructions,
            attachments: questData.attachments
        }

        await axiosInstance.post(`${apiUrl}/assignment/create?type=scroll&course_id=${id}`, assignment, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        message.success('Scroll created successfully');
        push(`/courses/${id}`);
    };

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: `${apiUrl}/upload?type=single`,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    return (
        <div>
            <Typography.Title>Create Scroll</Typography.Title>
            <Row gutter={16}>
                <Col span={18}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Assignment Name"
                            name="assignmentName"
                            rules={[{ required: true, message: 'Please input the assignment name!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Instructions"
                            name="instructions"
                            rules={[{ required: false }]}
                        >
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Attachments"
                            name="attachments"
                            valuePropName="fileList"
                            getValueFromEvent={e => getFile(e)}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click or drag files to upload
                                </p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item label="Points Possible" name="pointsPossible" initialValue={15}>
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item label="Attempts Allowed" name="attemptsAllowed" initialValue={1}>
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={6} className="right-section">
                    <div style={{
                        padding: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#ffffff'
                    }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                            <h3>Your PDFs</h3>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                <Input
                                    placeholder="Search PDFs"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <Button
                                    type="text"
                                    icon={sortDirection === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                                    onClick={() => setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))}
                                >
                                    Sort by date
                                </Button>
                            </div>
                            <List
                                dataSource={filteredPdfs}
                                loading={isLoading}
                                renderItem={(pdf) => {
                                    const formattedDate = new Date(pdf.created_at).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric'
                                    });
                                    return (<List.Item
                                        key={pdf.id}
                                        style={{ width: '100%', textAlign: 'center' }}
                                        actions={[
                                            <EyeOutlined onClick={() => handleModalOpen(pdf)} />,
                                            <PlusOutlined onClick={() => handleAddFromFile(pdf)} />,
                                        ]}
                                    >
                                        <Tag>{formattedDate}</Tag>
                                        {pdf.title}
                                    </List.Item>)
                                }}
                            />
                        </div>
                    </div>
                    {selectedPDF && (
                        <DefaultPdfModal
                            pdfURL={`${selectedPDF.url.replace('http://', 'https://')}#zoom=100`}
                            pdfMemoURL={`${data?.data?.find(memo => memo.id === selectedPDF.id)?.url.replace('http://', 'https://')}`}
                            pdfExplanationURL={`${explanationData?.data?.find(explanation => explanation.id === selectedPDF.id)?.url.replace('http://', 'https://')}`}
                            isModalOpen={isModalOpen}
                            onClose={handleModalClose}
                        />
                        // <Modal
                        //     open={isModalOpen}
                        //     title={selectedPDF.title}
                        //     onCancel={handleModalClose}
                        //     footer={null}
                        //     width="60%">
                        //     {!selectedPDF.url ? <Spin /> :
                        //         <object
                        //             data={`${selectedPDF.url.replace('http://', 'https://')}#zoom=100`}
                        //             title={selectedPDF.title}
                        //             style={{ border: 'none' }}
                        //             width="100%"
                        //             height={"700px"}
                        //         />}
                        // </Modal>
                    )}
                </Col>
            </Row>

        </div>
    );
};