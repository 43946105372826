import React, { useEffect, useState } from 'react';
import {
    Typography,
    Input,
    Button,
    Row,
    Col,
    List,
    Tag
} from 'antd';
import { useList, useNavigation } from '@refinedev/core';
import { EyeOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './create.css';
import { IFile } from 'interfaces';
import { DefaultPdfModal } from 'components/ImageSelection';

export const CreateQuest: React.FC = () => {
    const { push } = useNavigation();
    const { id } = useParams<{ id: string }>();

    const [selectedPDF, setSelectedPDF] = useState<IFile | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredPdfs, setFilteredPdfs] = useState<IFile[]>([]);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const { data: subjectData, isLoading: subjectLoading } = useList({
        resource: `subject/${id}`, 
    });

    const { data: questionData } = useList({
        resource: `files?type=questions`
    });

    const { data: explanationData } = useList({
        resource: `files?type=explanation`
    });

    const { data: memoData } = useList({
        resource: `files?type=memo`
    });

    const subject = subjectData?.data as any;
    const subject_name = subject?.name;

    const filterAndSortPdfs = () => {
        const filtered = (questionData?.data as IFile[] || []).filter((pdf) =>
            pdf.title.toLowerCase().includes(searchQuery.toLowerCase())
        );

        const sorted = filtered.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);

            return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        });

        setFilteredPdfs(sorted);
    };

    useEffect(() => {
        filterAndSortPdfs();
    }, [questionData, searchQuery, sortDirection]);

    const handleModalOpen = (file: IFile) => {
        setSelectedPDF(file);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedPDF(null);
        setIsModalOpen(false);
    };

    const createFromExamQuestions = () => {
        push(`/courses/${id}/assignments/create/questions?redirect=quest`);
    };

    const getCorrespondingUrls = (pdf: IFile) => {
        const baseName = pdf.title.replace(/ Memo\.pdf$| Explanation\.pdf$|\.pdf$/i, '');
    
        const memo = memoData?.data.find(memo => memo.title === `${baseName} Memo.pdf`);
        const explanation = explanationData?.data.find(explanation => explanation.title === `${baseName} Explanation.pdf`);
    
        return {
            pdfMemoURL: memo ? memo.url.replace('http://', 'https://') : null,
            pdfExplanationURL: explanation ? explanation.url.replace('http://', 'https://') : null,
        };
    };

    return (
        <div>
            <Typography.Title>{subjectLoading ? 'Loading...' : `${subject_name} Files`}</Typography.Title>
            <div style={{ marginBottom: '16px' }}>
                <Button type="primary" onClick={createFromExamQuestions} className="create-new-button">
                    Create new
                </Button>
            </div>
            <Row gutter={16}>
                <Col span={18}>
                    <h3>Your PDFs</h3>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                        <Input
                            placeholder="Search PDFs"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Button
                            type="text"
                            icon={sortDirection === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                            onClick={() => setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))}
                        >
                            Sort by date
                        </Button>
                    </div>
                    <div style={{ backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}>
                        <List
                            dataSource={filteredPdfs}
                            loading={!questionData}
                            renderItem={(pdf) => {
                                const formattedDate = new Date(pdf.created_at).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                });
                                return (
                                    <List.Item
                                        key={pdf.id}
                                        actions={[<EyeOutlined onClick={() => handleModalOpen(pdf)} />]}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Tag>{formattedDate}</Tag>
                                        {pdf.title}
                                    </List.Item>
                                );
                            }}
                        />
                    </div>
                    {selectedPDF && (
                        <DefaultPdfModal
                            pdfURL={`${selectedPDF.url.replace('http://', 'https://')}#zoom=100`}
                            {...getCorrespondingUrls(selectedPDF)}
                            isModalOpen={isModalOpen}
                            onClose={handleModalClose}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};